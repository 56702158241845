.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
}

.layout-header {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.2rem;
}

.layout-main {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.layout-sidebar {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.layout-sidebar button {
  display: block;
  margin: 1rem;
  padding: 0.5rem 1rem;
}

.layout-title {
  display: block;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
}

.layout-content {
  flex-grow: 1;
  /* background: beige; */
  padding: 1rem;
}

.layout-sidebar-2 {
  padding: 1rem;
}

.layout-footer {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  /* background-color: lightpink; */
  padding: 0.2rem;
}

.logo-box {
  display: flex;
  flex-direction: row;
  margin: 1rem;
}

@media screen and (max-width: 700px) {
  .layout-main {
    flex-direction: column;
  }
  .layout-sidebar {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-evenly;
  }
}